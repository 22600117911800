import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #006 - Scot Messner, District 1 Brewing"}
    blogUrl={"https://joincrafted.com/006-scot-messner-district-1-brewing"}
    description={
      "Scot Messner of District 1 Brewing (Steven's Point, WI) shares what it was like to brew his first production beer, Scot's Ryerish Red."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/district_1_brewing/Snapchat-487350608.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=Scot%20Messner%20of%20District%201%20Brewing%20(Steven's%20Point,%20WI)%20shares%20what%20it%20was%20like%20to%20brew%20his%20first%20production%20beer,%20Scot's%20Ryerish%20Red.%0A%0Ahttps%3A//joincrafted.com/006-scot-messner-district-1-brewing"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/006-scot-messner-district-1-brewing"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5"
              }}
            >
              <p>October 26, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              <table cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          <tr style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;006 🍻 - Scot Messner, District 1 Brewing</h1>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>We're all at different places when it comes to brewing. One awesome thing about the<em> craft</em> is that you can be a part of it at so many different levels. And no level is necessarily better than another. Just find the one that's right for you.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>But there is something to be said for "leveling-up" your skills. The more time you put into brewing, the better you get, the more you enjoy it. Or at least that's how it should be.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                That's why I'm excited to highlight <strong>Scot Messner</strong> of <a href="http://www.district1brewing.com/" style={{color: '#6821AB', fontWeight: 700, textDecoration: 'underline'}} target="_blank" rel="noreferrer"><strong>District 1 Brewing Co.</strong></a> He's doing it as it should be done; He's driven by the love of the craft. Scot works at the brewery on top of his day job, carving out time in his daily life to pursue his passion.
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Some of us just enjoy the final product and stop there. Some love homebrewing and spend our beer money on equipment and ingredients instead of on beer itself. Some have made brewing their full-time profession.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>We're all somewhere on our journey in crafting beer. Read on about Scot's journey - you'll find out about:</p>
                                                <ul>
                                                  <li>A Rye-leaning beer born on St. Patrick's 🍀</li>
                                                  <li>How success is about others</li>
                                                  <li>A first production brew, and a priceless moment</li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                        <tbody>
                                          <tr>
                                            <td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                              <div className="text-block" style={{lineHeight: '150%'}}>
                                                <EmailFormInPage />
                                                <div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>How long have you been brewing and how'd you get into it?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I started homebrewing back in 2012 with a Mr. Beer kit. I made one beer with that kit and immediately switched over to 5 gallon partial mash brewing. I like crafting things and liked the idea of making beer for my friends and family. Honestly I think I give away more of my homebrew than I drink!</p>
                                                <p style={{paddingBottom: '16px'}}>The thing that really got me hooked was the social side of brewing, especially the way beer brings people together. I was the black sheep of my family growing up and craft beer, especially microbreweries, helped me to find common ground and a shared interest with my older brother and father and thus build a relationship with both of them that I hadn't had in my youth.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/district_1_brewing/102662970_3004360679610126_3695495590688325632_o.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                American IPA from District 1, where people come together and share good times in Steven's Point, WI
                                              </div>
                                              <div style={{wordWrap: 'break-word', textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5'}}>
                                                Image source: https://www.facebook.com/D1Brewing
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the one beer that you are most proud of? How was this beer born and what makes it special to you?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>At this stage, the beer I'm most proud of is probably the <a href="https://untappd.com/b/district-1-brewing-company-scot-s-ryerish-red/4004116" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Ryerish Red</a>. As the name would suggest, it's an Irish Red brewed with 15% rye malt. This is the first beer I've had the opportunity to brew for production and thus far it has had a really great reception. Brewing at a professional level is something I've always felt was out of my reach. Seeing this beer on tap is just something else.</p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  This recipe is the result of some experimentation I was doing with 1 gallon beers a few years back. I had been playing with using different adjunct ingredients in traditional recipes and was looking for a nice balance between making something unusual, but still easy-drinking. I wanted a beer that was a bit funky but wasn't going to scare people away.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>My wife had started a new teaching job and her aide had invited us to their annual St. Patrick's Day party. We were both pretty fresh out of college and the folks that attended this party every year were all "grown ups," who had nice cars, houses, kids who were our age and I just wanted to walk in with a batch of home brew and just knock their socks off with it.</p>
                                                <p style={{paddingBottom: '16px'}}>I settled on an Irish Red for obvious reasons and due to my love of rye, I figured go big or go home. And a red with a hefty dose of rye was born. The beer went over well and we've been invited back every year since. </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/district_1_brewing/Snapchat-487350608.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Great color on Scot's Ryerish Red
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Do you have a role in recipe design? Can you tell us about your creative process for that?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Since my primary role with in the brewery is not brewing, my role in recipe design is more theoretical. I spend most of my time in the trenches, finding out what people want to see us make and reporting that back. Our brewmaster is also pretty open to new ideas and suggestions when it comes to new brews and what we should brew next.</p>
                                                <p style={{paddingBottom: '16px'}}>As far as in my personal brewing and development of my future production recipes, I feel that balance is super important. Everyone wants to make a new beer no one's ever seen or tasted, but that gets more and more difficult every day. My philosophy revolves around taking a well-loved style and finding a way to accent and highlight specific flavors.</p>
                                                <p style={{paddingBottom: '16px'}}>I like nutty, earthy, strong flavors and tend to gravitate towards highlighting those flavors while still making a drinkable beer for the masses. I'm a bit of a people pleaser and tend take pride in making a beer that people will enjoy rather than really push the boundaries of brewing. Not that there's anything wrong with that, that's just not me. </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What's one thing that aspiring brewers should know about the job, something you wish you'd known?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Not that I didn't know going in, but a lot of people don't seem to realize just how much cleaning and planning is involved. If you hate cleaning, brewing probably isn't for you.</p>
                                                <p style={{paddingBottom: '16px'}}>Another thing a lot of people looking to start a new brewery don't realize is just how much money you need to get started. Budget-in more operating costs and a larger system than you think you need. If you're successful and have any growth you will likely outgrow a 1-2 bbl system much sooner than you think. Who wants to upgrade before you've even paid off your original system?</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is success to you, regarding brewing and craft beer?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I want to say that brewing success is hitting all my numbers and getting a recipe to turn out exactly as I envisioned, but in reality, when it comes to crafting beer, I view success is making a beer that customers enjoy enough to spend money on. Seeing a customer order my beer and say, "Oh, that's good," is one of the best feelings in the world.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="assets/images/district_1_brewing/FB_IMG_1603392536256.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', textAlign: 'center'}}>
                                                Scot at District 1 Brewing
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                        <tbody>
                                          <tr>
                                            <td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                              <div className="text-block" style={{lineHeight: '150%'}}>
                                                <EmailFormInPage />
                                                <div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>How awesome must it feel to see your first brew on tap, <strong>to see people buying what you worked hard to create, to see memories being made around what you crafted?</strong> For all you brewers out there, do you remember that first time?</p>
                                                <p style={{paddingBottom: '8px'}}>Scot's parents were there when Ryerish Red was first tapped. It was his dad's birthday, and he couldn't stop smiling. As Scot said, <em>You really can't put a price on seeing your father literally beaming with pride. I'll never forget that moment. And I think it was a birthday he won't soon forget.</em></p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>Brewing a good beer is already pretty cool. But it wouldn't mean much without people to share it with. Having a good time, putting smiles on faces - that's where it's at.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px', margin: '15px 0'}}>
                                                Thank you <strong>Scot</strong> for sharing your journey with us. Your joy for the craft inspires me. May the Ryerish Red be just the first of many production brews to come!
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I also want to thank you all for reading this, for making this newsletter happen. You make it worth it. I'm blessed to be a part of this growing, craft-beer-loving community.</p>
                                                <p style={{paddingBottom: '16px'}}>Take care, until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px'}}>
                                                <div style={{padding: '8px'}}>
                                                  This week's newsletter is sponsored by <strong>Yakima Valley Hops</strong>. They do a lot for the homebrewing community. <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit" style={{textDecoration: 'underline', color: '#6821AB'}} target="_blank" rel="noreferrer">Check out their new hop aromas kit</a>, made for brewers to hone their hop noses and refine the language of their beer.
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '-8px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit" target="_blank" rel="noreferrer"><img src="https://gallery.eocampaign.com/5d6ee9b8-e53b-11ea-a3d0-06b4694bee2a%2F1603669031852-yakima_valley_hops_kit_1000x500.jpeg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} alt="Yakima Valley Hops Kit" /></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
